import { map } from "./map";
import { baseurl, imageurl } from "../../config";
import GeoJSON from "ol/format/GeoJSON.js";
import { Vector as VectorSource } from "ol/source.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { Style, Icon } from "ol/style.js";
import View from "ol/View.js";
import { fromLonLat } from "ol/proj.js";
import { content, overlay } from "./overlay";
import { createoverlay } from "./function";

// Define icons
const consumerIcon = imageurl + "Blue.png";
const prosumerIcon = imageurl + "Solar.png";
// const prosumerIcon = "http://localhost/mapimage/Solar.png";

// Function to add features to a vector layer
function addFeaturesToLayer(layer, format, features) {
  layer.getSource().addFeatures(
    format.readFeatures(
      {
        type: "FeatureCollection",
        features: features,
      },
      {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      }
    )
  );
}

// Style function
const styleFunction = (feature) => {
  const consumerType = feature.get("ConsumerType");
  let iconSrc;

  if (consumerType === "prosumer") {
    iconSrc = prosumerIcon;
    return new Style({
      image: new Icon({
        scale: [0.3, 0.3],
        anchor: [0.5, 0.5],
        src: iconSrc,
      }),
    });
  } else if (consumerType === "consumer") {
    iconSrc = consumerIcon;
    return new Style({
      image: new Icon({
        scale: [1, 1],
        anchor: [0.5, 0.5],
        src: iconSrc,
      }),
    });
  } else {
    return null; // Return null if no match
  }
};

// Instantiate GeoJSON format
let geojsonFormat = new GeoJSON();

// Instantiate vector source and layer
let meterSource = new VectorSource();
let meterLayer = new VectorLayer({
  title: "Meter Location",
  visible: true,
  source: meterSource,
  style: styleFunction, // Apply the style function
});

// Add the vector layer to the map
map.addLayer(meterLayer);

// Fetch meter data from the server
$.ajax({
  url: baseurl + "/meter/getAll",
  type: "GET",
  contentType: "application/json",
  beforeSend: function () {
    map.getTargetElement().classList.add("map_spinner");
  },
  success: function (data) {
    map.getTargetElement().classList.remove("map_spinner");
    // console.log(data.message);
    const meterData = data.data;
    // console.log(meterData, "api data");

    // Filter out items with invalid latitude or longitude
    const validMeterData = meterData.filter(
      (item) => item.latitude !== null && item.longitude !== null
    );

    // Create GeoJSON features from valid meter data
    let geojson = {
      type: "FeatureCollection",
      features: validMeterData.map((item) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [item.latitude, item.longitude], // Ensure correct coordinate format
        },
        properties: {
          Phase: item.Phase,
          Accuracy: item.accuracy,
          Consumer: item.consumer,
          ConsumerType: item.consumerType,
          CreatedAt: item.createdAt,
          flowType: item.flowType,
          imageUrl: item.imageUrl,
          inverter_frequency: item.inverter_frequency,
          manufacturer: item.manufacturer,
          meterNo: item.meterNo,
          meterType: item.meterType,
          model: item.model,
          plantCode: item.plantCode,
          serialNumber: item.serialNumber,
          settlementType: item.settlementType,
          updatedAt: item.updatedAt,
          user: item.user,
          __v: item.__v,
          _id: item._id,
        },
      })),
    };

    // console.log(geojson);
    // Add features to the vector layer
    addFeaturesToLayer(meterLayer, geojsonFormat, geojson.features);

    // Zoom to the extent of the features
    const extent = meterLayer.getSource().getExtent();
    // map.getView().fit(extent, { duration: 1000 });
    // Fit the view to the extent with padding to ensure all points are visible
    map.getView().fit(extent, {
      duration: 1000,       // Animation duration (1 second)
      padding: [50, 50, 50, 50],  // Padding around the extent [top, right, bottom, left]
      maxZoom: 18           // Set a maximum zoom level (adjust as necessary)
    });

    // Optionally set a fixed zoom level after fitting the extent
    // map.getView().setZoom(18); // Set your desired zoom level here
  },
  error: function (xhr, status, error) {
    map.getTargetElement().classList.remove("map_spinner");
    $.notify(xhr.responseText, {
      color: "#fff",
      background: "#D44950",
      delay: 5000,
    });
  },
});

// Define the map view to focus on a certain area
map.setView(
  new View({
    center: fromLonLat([0, 0]), // Set a default center (change as needed)
    zoom: 2, // Set an initial zoom level
  })
);

map.on("singleclick", function (evt) {
  // Remove existing overlay if no feature is found
  let featureFound = false;

  map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    featureFound = true;
    const properties = feature.getProperties();
    // console.log("Properties:", properties.user);
    const user = properties.user;

    $.ajax({
      url: baseurl + "/meter/ots/" + user,
      type: "GET",
      contentType: "application/json",
      beforeSend: function () {
        map.getTargetElement().classList.add("map_spinner");
      },
      success: function (data) {
        map.getTargetElement().classList.remove("map_spinner");
        const meterData = data.data;
        // console.log(meterData, "api data");
        if (meterData) {
          createoverlay(evt, content, meterData, properties, overlay);
        } else {
          $.notify("No Data Found", {
            color: "#fff",
            background: "#D44950",
            delay: 5000,
          });
        }
      },
      error: function (xhr, status, error) {
        map.getTargetElement().classList.remove("map_spinner");
        $.notify(xhr.responseText, {
          color: "#fff",
          background: "#D44950",
          delay: 5000,
        });
      },
    });
  });

  if (!featureFound) {
    overlay.setPosition(undefined);
  }
});
