import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { overlay } from "./overlay";

const map = new Map({
  target: "map",
  layers: [
    new TileLayer({
      source: new OSM(),
    }),
  ],
  overlays: [overlay],
  view: new View({
    center: [0, 0],
    zoom: 2,
  }),
});

map.on("loadstart", function () {
  map.getTargetElement().classList.add("map_spinner");
});

map.on("loadend", function () {
  map.getTargetElement().classList.remove("map_spinner");
});

export { map };
