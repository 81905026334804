function createoverlay1(evt, content, data, properties, overlay) {
  let template = `<div class="container text-center">
            <div class="row align-items-center" style="background-color: ${properties.ConsumerType == "prosumer" ? "#f47085" : "#2d82b7"}; height: 40px;">
              <div class="col-auto" style="padding: 0px;">
                <div class="dot"></div>
              </div>
              <div class="col-auto">
                <div class="prosumer-name">${properties.Consumer}</div>
              </div>
              <div class="col-auto ml-auto">
                <a href="#" class="ol-popup-closer"></a>
              </div>
            </div>
            <div class="row p-1">
              <div class="col text-left">User ID</div>
              <div class="col text-muted text-left">${data._id}</div>
            </div>
            <div class="row p-1">
              <div class="col text-left">Order Unit</div>
              <div class="col text-muted text-left">${data.totalUnits}</div>
            </div>
            <div class="row p-1">
              <div class="col text-left">Trade Unit</div>
              <div class="col text-muted text-left">${data.totalTradeUnits}</div>
            </div>
            <div class="row p-1">
              <div class="col text-left">Settlement Unit</div>
              <div class="col text-muted text-left">${data.totalSettledUnits}</div>
            </div>
          </div>`;

  content.innerHTML = template;
  const coordinate = evt.coordinate;
  overlay.setPosition(coordinate);

  // Add event listener for the close button
  const closer = content.querySelector('.ol-popup-closer');
  closer.addEventListener('click', function () {
    overlay.setPosition(undefined);
    closer.blur(); // Remove focus from the button
    return false;
  });
}

function createoverlay(evt, content, data, properties, overlay) {
  let TotalUnits = data.totalUnits;
  let TotalTradeUnits = data.totalTradeUnits;
  let TotalSettledUnits = data.totalSettledUnits;

  let units = TotalUnits.toFixed(2);        // Corrected toFixed method
  let tradeunits = TotalTradeUnits.toFixed(2); 
  let settledunits = TotalSettledUnits.toFixed(2);

  let template = `
     <div class="container text-center">
            <div class="row align-items-center" style="background-color: ${properties.ConsumerType == "prosumer" ? "#fcb616" : "#2d82b7"}; height: 40px;">
              <div class="col-auto" style="padding: 0px;">
                <div class="dot"></div>
              </div>
              <div class="col-auto">
                <div class="prosumer-name">${properties.Consumer}</div>
              </div>
              <div class="col-auto ml-auto">
                <a href="#" class="ol-popup-closer"></a>
              </div>
            </div>
             <table class="table table-borderless">
          <tbody class="text-left">
            <tr>
              <td>User ID</th>
              <td>${data._id}</td>
            </tr>
            <tr>
              <td>Order Unit</th>
              <td>${units}</td>
            </tr>
            <tr>
              <td>Trade Unit</th>
              <td colspan="2">${tradeunits}</td>
            </tr>
            <tr>
              <td>Settlement Unit</th>
              <td colspan="2">${settledunits}</td>
            </tr>
          </tbody>
        </table>
          </div>
  `;

  content.innerHTML = template;
  const coordinate = evt.coordinate;
  overlay.setPosition(coordinate);

  // Add event listener for the close button
  const closer = content.querySelector('.ol-popup-closer');
  closer.addEventListener('click', function () {
    overlay.setPosition(undefined);
    closer.blur(); // Remove focus from the button
    return false;
  });
}

export {createoverlay}